import { combineReducers } from '@reduxjs/toolkit';
import tableReducer from '../table/tableReducer';
import lobbyReducer from '../lobby/lobbyReducer';
import walletsReducer from '../wallets/walletsReducer';
import playerReducer from '../players/playerReducer';
import chatReducer from '../chat/chatReducer';
import authReducer from '../auth/authReducer';
import rakeReducer from '../rake/rakeReducer';
import depositBonusReducer from '../deposit-bonus/depositBonusReducer';
import leaderboardReducer from '../leaderboard/leaderboardReducer';

export interface RootState {
  tables: ReturnType<typeof tableReducer>;
  lobby: ReturnType<typeof lobbyReducer>;
  players: ReturnType<typeof playerReducer>;
  auth: ReturnType<typeof authReducer>;
  wallets: ReturnType<typeof walletsReducer>;
  rake: ReturnType<typeof rakeReducer>;
  depositBonus: ReturnType<typeof depositBonusReducer>;
  leaderboard: ReturnType<typeof leaderboardReducer>;
}

const rootReducer = combineReducers({
  lobby: lobbyReducer,
  tables: tableReducer,
  chats: chatReducer,
  players: playerReducer,
  auth: authReducer,
  wallets: walletsReducer,
  rake: rakeReducer,
  depositBonus: depositBonusReducer,
  leaderboard: leaderboardReducer,
});

export default rootReducer;
