import { createSlice } from '@reduxjs/toolkit';

import { fetchLeaderboardThunk } from './leaderboardThunks';
import { LeaderboardState } from './LeaderboardState';

const leaderboardSlice = createSlice({
  name: 'leaderboard',
  initialState: {
    leaderboard: null,
  } as LeaderboardState,
  reducers: {
    setLeaderboard: (state, action) => {
      state.leaderboard = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchLeaderboardThunk.fulfilled, (state, action) => {
      state.leaderboard = action.payload;
    });
  },
});

export default leaderboardSlice.reducer;
