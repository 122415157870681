import { all, fork, takeEvery, takeLeading, call, takeLatest } from 'redux-saga/effects';

import lobbyRootSaga from '../lobby/sagas/lobbyRootSaga';
import tableRootSaga from '../table/sagas/tableRootSaga';
import authRootSaga from '../auth/sagas/authRootSaga';

import { TableRequests, TableIncomingEvents } from '../table/tableActions';
import { ChatActions } from '../chat/chatActions';
import { LobbyRequest } from '../lobby/lobbyActions';
import {
  connectAllTables,
  openAllTables,
  handleTablePlayerAutoTopUpSaga,
  handleRebuyProcessedSaga,
} from '../table/sagas/tableHandleSaga';
import watchTableInit from '../table/sagas/tableInitSaga';
import getChatsHistorySaga from '../chat/sagas/getChatsHistorySaga';
import getMutedUsersSaga from '../chat/sagas/getMutedUsersSaga';

function* handleChatRequestHistory(action: { type: ChatActions.REQUEST_HISTORY; tableId: string; tableUrl: string }) {
  yield all([call(getChatsHistorySaga, action), call(getMutedUsersSaga, action)]);
}

export default function* rootSaga() {
  yield takeLeading(TableRequests.INIT, watchTableInit);
  yield fork(authRootSaga);
  yield fork(lobbyRootSaga);
  yield takeEvery(TableRequests.TABLE_CONNECT, tableRootSaga);
  yield takeEvery(TableRequests.TABLE_DISCONNECT, tableRootSaga);
  yield takeLatest(TableIncomingEvents.AUTO_TOP_UP, handleTablePlayerAutoTopUpSaga);
  yield takeEvery(TableIncomingEvents.REBUY_PROCESSED, handleRebuyProcessedSaga);
  yield all([
    takeEvery(LobbyRequest.OPEN_ALL_TABLES, openAllTables),
    takeEvery(LobbyRequest.CONNECT_ALL_TABLES, connectAllTables),
    takeEvery(ChatActions.REQUEST_HISTORY, handleChatRequestHistory),
  ]);
}
