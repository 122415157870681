import axiosBaseApi from '@/services/common/lib/axiosBaseApi';
import { FETCH_USER_BONUS_DATA, REGISTER_USER_FOR_BONUS } from './depositBonusActions';

import { createAsyncThunk } from '@reduxjs/toolkit';

export const fetchUserBonusData = async () => await axiosBaseApi.get<any>(`/v1/deposit-bonus/bonuses`);

export const registerUserForBonus = async (code: string) =>
  await axiosBaseApi.post<any>(`/v1/deposit-bonus/${code}/register`);

export const fetchUserBonusDataThunk = createAsyncThunk(FETCH_USER_BONUS_DATA, async () => {
  const response = await fetchUserBonusData();
  return response.data;
});

export const registerUserForBonusThunk = createAsyncThunk(
  REGISTER_USER_FOR_BONUS,
  async ({ code }: { code: string }) => {
    const response = await registerUserForBonus(code);
    return response.data;
  },
);
