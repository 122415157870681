import { Card } from '../../../services/table/tableTypes';

export const parseWinningHand = (winning_hand: string): { text: string; cards: Card[] } => {
  const textRegex = /^([^(]+)/;

  const matchText = winning_hand.match(textRegex);

  const regex = /\(([^)]+)\)/;
  const matches = winning_hand.match(regex);
  const winningHandText = matchText ? matchText[0] : '';

  if (!matches) return { text: winningHandText, cards: [] };

  const content = matches[1];
  const pairs = content.match(/.{1,2}/g) || [];

  const result = pairs.map((pair) => ({
    rank: pair[0],
    suit: pair[1],
  }));

  return { text: winningHandText, cards: result };
};
