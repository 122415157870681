import { polygon } from 'viem/chains';
import { Config } from './config.interface';

export const localConfig: Config = {
  environment: 'local',
  smartWalletEnabled: true,
  contracts: {
    usdt: '0x2A1e0E339355899f5F7f7c1F4590a79D6B49D4F2',
    phnm: '0x35F4a9FdcBddBaF7C3c3FF45f78Dc5BF70EeFDA1',
    tables: '0xd841DaCe0EfdD174526daDBaA38f3358144bbe91',
    redemption: '0x746c1e55d1FBeb0f6081C8b6805395E9f3811dDE',
    redemptionVault: '0x99810bF501E1122b209353AE0234a3cA94a73522',
  },
  chain: polygon,
  blockExplorerUrl: 'https://polygonscan.com',
  usdtDecimals: 18,
  phnmDecimals: 18,
  postHostApiKey: 'phc_BJuZLS9RyWv2BWm5ltPfe0dweOaBqnPhVYWLAUavm0X',
  ethereumRpcUrl: 'https://twilight-frosty-dinghy.quiknode.pro/4ffcfdf0821bfb32db1339b4a0cf59ff038a7636',
  polygonRpcUrl: 'https://side-damp-spree.matic.quiknode.pro/787fca9d6bc053f7aacff150f391ef28b3677666',
};
