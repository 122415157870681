import { LobbyState } from './lobbyState';
import { LobbyIncomingEvents, LobbyInternalEvents } from './lobbyActions';
import { LobbyIncomingEventMsg, LobbyInternalEventMsg } from './lobbyMessages';

const initialState: LobbyState = {
  tables: [],
  userTables: [], // TODO : TO BE MOVED TO USER REDUCER
  isConnected: true,
  playersOnline: 0,
  activeCashTables: 0,
  activeCashTournaments: 0,
  tableBuyIn: null,
  waitlistSeatReady: undefined,
  visitorIdToKeep: undefined,
  showTransferModal: false,
  showSettingsModal: false,
  showRewardsModal: false,
  showConfirmBonusChangeModal: false,
  openModalTab: 0,
};

function lobbyReducer(state = initialState, action: LobbyIncomingEventMsg | LobbyInternalEventMsg): LobbyState {
  switch (action.type) {
    case LobbyInternalEvents.LOBBY_CONNECTED:
      return { ...state, isConnected: true };
    case LobbyInternalEvents.LOBBY_DISCONNECTED:
      return { ...state, isConnected: false };
    case LobbyInternalEvents.LOBBY_REFRESHED:
      return action.data;
    case LobbyInternalEvents.OPEN_BUY_IN: {
      return {
        ...state,
        tableBuyIn: { ...action.data, open: true, isRebuy: action.data.isRebuy },
      };
    }
    case LobbyInternalEvents.UPDATE_BUY_IN: {
      return {
        ...state,
        tableBuyIn: { ...state.tableBuyIn, ...action.data },
      };
    }

    case LobbyIncomingEvents.SEAT_READY: {
      const waitlistSeatReady = action.data;

      return {
        ...state,
        waitlistSeatReady,
      };
    }
    case LobbyInternalEvents.CANCEL_SEAT_READY: {
      return {
        ...state,
        waitlistSeatReady: undefined,
      };
    }
    case LobbyInternalEvents.CLOSE_BUY_IN: {
      return {
        ...state,
        tableBuyIn: null,
      };
    }
    case LobbyIncomingEvents.DUPLICATE_LOGIN: {
      return {
        ...state,
        visitorIdToKeep: action.data.visitorId,
      };
    }

    case LobbyInternalEvents.OPEN_REWARDS_MODAL: {
      return {
        ...state,
        showRewardsModal: true,
      };
    }

    case LobbyInternalEvents.CLOSE_REWARDS_MODAL: {
      return {
        ...state,
        showRewardsModal: false,
      };
    }

    case LobbyInternalEvents.OPEN_TRANSFER_MODAL: {
      return {
        ...state,
        showTransferModal: true,
      };
    }

    case LobbyInternalEvents.CLOSE_TRANSFER_MODAL: {
      return {
        ...state,
        showTransferModal: false,
      };
    }

    case LobbyInternalEvents.OPEN_SETTINGS_MODAL: {
      return {
        ...state,
        showSettingsModal: true,
      };
    }

    case LobbyInternalEvents.CLOSE_SETTINGS_MODAL: {
      return {
        ...state,
        showSettingsModal: false,
      };
    }

    case LobbyInternalEvents.SET_MODAL_TAB: {
      return {
        ...state,
        openModalTab: action.payload,
      };
    }

    case LobbyInternalEvents.OPEN_CONFIRM_BONUS_CHANGE_MODAL: {
      return {
        ...state,
        showConfirmBonusChangeModal: true,
      };
    }

    case LobbyInternalEvents.CLOSE_CONFIRM_BONUS_CHANGE_MODAL: {
      return {
        ...state,
        showConfirmBonusChangeModal: false,
      };
    }

    default:
      return state;
  }
}

export default lobbyReducer;
