import { ComponentProps, useMemo } from 'react';
import { twMerge } from 'tailwind-merge';

import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';

export enum ButtonVariants {
  primary = 'primary',
  secondary = 'secondary',
  transparent = 'transparent',
  pokerTable = 'pokerTable',
  transparentWithBorder = 'transparentWithBorder',
  transparentWithBorderRounded = 'transparentWithBorderRounded',
}
export enum ButtonSizes {
  xs = 'xs',
  sm = 'sm',
  md = 'md',
  lg = 'lg',
  xl = 'xl',
  none = 'none',
}

type ButtonProps = {
  variant?: ButtonVariants;
  fullWidth?: boolean;
  size?: ButtonSizes;
  isLoading?: boolean;
  textClassName?: string;
} & ComponentProps<'button'>;

const Button = ({
  variant = ButtonVariants.primary,
  fullWidth = false,
  size = ButtonSizes.lg,
  isLoading = false,
  className,
  textClassName,
  ...props
}: ButtonProps) => {
  const variantStyle = useMemo(() => {
    switch (variant) {
      case ButtonVariants.primary:
        return 'bg-ph-primary-violet hover:bg-none hover:bg-ph-primary-violet-lighter text-white';
      case ButtonVariants.secondary:
        return 'bg-ph-gray-cod border-2 border-ph-gray-silver text-ph-gray-silver hover:text-ph-white  hover:border-ph-white active:text-white active:border-white';
      case ButtonVariants.transparent:
        return 'text-ph-gray-silver hover:text-ph-secondary-portage  active:text-white bg-transparent';
      case ButtonVariants.transparentWithBorder:
        return 'min-w-15 !h-9 flex justify-center rounded-sm items-center active:text-white bg-transparent border-ph-gray-silver border-[1px]';
      case ButtonVariants.transparentWithBorderRounded:
        return 'flex justify-center items-center text-white bg-transparent border-ph-gray-silver border-[1px]  hover:text-ph-white-50  hover:border-ph-white-50';
      case ButtonVariants.pokerTable:
        return 'poker-user-actions-button text-white text-opacity-50';
    }
  }, [variant]);

  const variantStyleDisabled = useMemo(() => {
    switch (variant) {
      case ButtonVariants.pokerTable:
        return 'poker-user-actions-button opacity-20';
      default:
        return 'bg-ph-gray-baltic text-ph-gray-jumbo hover:cursor-not-allowed border-none';
    }
  }, [variant]);

  const sizeStyle = useMemo(() => {
    switch (size) {
      case ButtonSizes.xl:
        return 'px-6 py-4 h-11';
      case ButtonSizes.lg:
        return 'px-5 py-3 h-11';
      case ButtonSizes.md:
        return 'px-5 py-2';
      case ButtonSizes.sm:
        return 'px-4 py-2';
      case ButtonSizes.xs:
        return 'px-2 py-1';
      case ButtonSizes.none:
        return '';
      default:
        return 'p-5 py-3';
    }
  }, [size]);

  const textStyle = useMemo(() => {
    switch (size) {
      case ButtonSizes.xs:
        return 'text-2xs';
      default:
        return 'text-sm';
    }
  }, [size]);

  return (
    <button
      disabled={props.disabled}
      className={twMerge(
        'flex items-center justify-center gap-2 rounded-lg text-[16px] font-bold leading-5 tracking-wide',
        !props.disabled ? variantStyle : variantStyleDisabled,
        fullWidth && '!w-full',
        sizeStyle,
        className,
      )}
      {...props}
    >
      {isLoading && <LoadingSpinner size="4" fullWidth={false} borderColor="border-white" />}
      <span className={twMerge(textStyle, 'flex items-center justify-center gap-2', textClassName)}>
        {props.children}
      </span>
    </button>
  );
};

export default Button;
