export function formatCurrency(
  amount: number | undefined,
  config?: {
    decimals?: number;
    locale?: string;
    shortFormat?: boolean;
    mobileFormat?: boolean;
  },
): string {
  const { decimals = 2, locale = 'en-US', shortFormat = false, mobileFormat = false } = config || {};

  if (!amount) {
    return shortFormat || mobileFormat || decimals === 0 ? '0' : '0.00';
  }

  let finalDecimals = decimals;

  if (decimals === 0 || shortFormat || mobileFormat) {
    finalDecimals = Number.isInteger(amount) ? 0 : 2;
  }

  if (shortFormat && amount >= 1000) {
    amount /= 1000; // Use shorthand division assignment
    return `${Intl.NumberFormat(locale, {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    }).format(amount)}K`; // Inline the finalAmount variable
  }

  return Intl.NumberFormat(locale, {
    style: 'decimal',
    minimumFractionDigits: finalDecimals,
    maximumFractionDigits: finalDecimals,
  }).format(amount);
}
