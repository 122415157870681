import { createSlice } from '@reduxjs/toolkit';

import { fetchUserBonusDataThunk, registerUserForBonusThunk } from './depositBonusThunks';
import { DepositBonusState } from './depositBonusState';
import { UserDepositBonus } from './depositBonus.types';
import { toast } from '@/components/toast/useToast';

const depositBonusSlice = createSlice({
  name: 'depositBonus',
  initialState: {
    activeBonus: [],
    availableBonus: [],
    bonusHistory: [],
    fetched: false,
    totalBonus: 0,
    totalLockedFunds: 0,
    bonusCode: '',
    appliedBonus: null,
    selectedBonus: null,
  } as DepositBonusState,
  reducers: {
    setBonusCode: (state, action) => {
      state.bonusCode = action.payload;
    },
    setActiveBonus: (state, action) => {
      state.activeBonus = action.payload;
    },
    setAvailableBonus: (state, action) => {
      state.availableBonus = action.payload;
    },
    setSelectedBonus: (state, action) => {
      state.selectedBonus = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserBonusDataThunk.fulfilled, (state, action) => {
      state.activeBonus = action.payload.activeBonus;
      state.availableBonus = action.payload.availableBonus;
      state.bonusHistory = action.payload.bonusHistory;

      state.fetched = true;

      const { totalBonus, totalLockedFunds } = action.payload.activeBonus.reduce(
        (acc: { totalBonus: number; totalLockedFunds: number }, curr: UserDepositBonus) => {
          acc.totalBonus += curr.bonusEarned;
          acc.totalLockedFunds += curr.totalLocked;
          return acc;
        },
        {
          totalBonus: 0,
          totalLockedFunds: 0,
        },
      );

      state.totalBonus = totalBonus;
      state.totalLockedFunds = totalLockedFunds;

      if (action.payload.activeBonus.length > 0) {
        const activeBonus = action.payload.activeBonus[0];

        state.appliedBonus = activeBonus;

        // bonus code should not be set if bonus is not active
        if (!activeBonus.isCompleted) {
          state.bonusCode = activeBonus?.depositBonus?.bonusCode;
        }
      } else {
        state.appliedBonus = null;
        state.bonusCode = '';
      }
    });
    builder.addCase(registerUserForBonusThunk.fulfilled, (state, action) => {
      const depositBonus = state.availableBonus.find((bonus) => bonus.id === action.payload.depositBonusId);

      const newActiveBonus = {
        ...action.payload,
        depositBonus,
      };

      state.activeBonus = [newActiveBonus];
      state.availableBonus = state.availableBonus.filter((bonus) => bonus.id !== depositBonus?.id);
      state.appliedBonus = newActiveBonus;
      state.bonusCode = newActiveBonus?.depositBonus?.bonusCode;
    });
    builder.addCase(registerUserForBonusThunk.rejected, () => {
      toast({
        title: 'Could not activate bonus',
        description: 'Verify the code you are using and try again.',
        variant: 'destructive',
      });
    });
  },
});

export const { setBonusCode, setSelectedBonus } = depositBonusSlice.actions;
export default depositBonusSlice.reducer;
