import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducer';
import { createLogger } from 'redux-logger';
import { SagaMiddleware } from 'redux-saga';
import createSagaMiddleware from 'redux-saga';
import rootSaga from './rootSaga';

const sagaMiddleware: SagaMiddleware = createSagaMiddleware();

const logger =
  process.env.NODE_ENV !== 'production'
    ? createLogger({
        collapsed: true,
        predicate: (_getState, action) => action.type !== 'PLAYER_TURN',
      })
    : null;

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    logger ? getDefaultMiddleware().concat(logger, sagaMiddleware) : getDefaultMiddleware().concat(sagaMiddleware),
});

export type AppDispatch = typeof store.dispatch;

sagaMiddleware.run(rootSaga);
