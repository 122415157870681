import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import useIsPortraitView from '../hooks/useIsPortraitView';
import BREAKPOINTS from '@/common/constant/breakpoints';

type BreakpointStates = Record<(typeof BREAKPOINTS)[keyof typeof BREAKPOINTS], boolean>;

interface MobileViewContextValue {
  breakpoints: BreakpointStates;
  isMobileDevice: boolean;
}

interface MobileViewProviderProps {
  children: React.ReactNode;
}

const initialBreakpointStates: BreakpointStates = Object.values(BREAKPOINTS).reduce(
  (acc, bp) => ({ ...acc, [bp]: false }),
  {},
);
const MobileViewContext = createContext<MobileViewContextValue>({
  breakpoints: initialBreakpointStates,
  isMobileDevice: false,
});

export const useMobileContext = () => useContext(MobileViewContext);

export const MobileViewProvider: React.FC<MobileViewProviderProps> = ({ children }) => {
  const isPortrait = useIsPortraitView();

  const memoIsPortrait = useMemo(() => isPortrait, [isPortrait]);
  const [breakpointStates, setBreakpointStates] = useState<BreakpointStates>(initialBreakpointStates);

  useEffect(() => {
    const checkBreakpoints = () => {
      const newBreakpointStates: BreakpointStates = Object.keys(BREAKPOINTS).reduce(
        (acc, bp) => ({
          ...acc,
          [BREAKPOINTS[bp as keyof typeof BREAKPOINTS]]:
            window.innerWidth < BREAKPOINTS[bp as keyof typeof BREAKPOINTS] && memoIsPortrait,
        }),
        {},
      );

      setBreakpointStates(newBreakpointStates);
    };

    checkBreakpoints();
    window.addEventListener('resize', checkBreakpoints);
    return () => window.removeEventListener('resize', checkBreakpoints);
  }, [memoIsPortrait]);

  const breakpoints = useMemo(() => breakpointStates, [breakpointStates]);

  const isMobileDevice = useMemo(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    const mobileKeywords = ['android', 'webos', 'iphone', 'ipad', 'ipod', 'blackberry', 'windows phone'];
    const isMobile = mobileKeywords.some((keyword) => userAgent.includes(keyword));

    document.documentElement.setAttribute('mobile-device', isMobile.toString());
    document.documentElement.setAttribute('data-mobile', isMobile.toString());

    return isMobile;
  }, []);

  const contextState = useMemo(
    () => ({
      breakpoints,
      isMobileDevice,
    }),
    [breakpoints, isMobileDevice],
  );

  return <MobileViewContext.Provider value={contextState}>{children}</MobileViewContext.Provider>;
};
